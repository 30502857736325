import React from "react"
import Container from "partials/container"
import Image from "components/Img"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from 'react-i18next';
import { Link } from "gatsby";
import { useMappingRoutes } from 'utils/useMappingRoutes';

const Footer = ({labelRoutes, pageContext}) => {
  const mappedRoutes = useMappingRoutes(pageContext.langKey)
  const { t } = useTranslation();
  const onScrollTop = e => {
    e.stopPropagation()

    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  const { routes } = labelRoutes;
  const {
    services: labelServices,
    about: labelAbout,
    contact: labelContact,
    faq: labelFaq,
    careers: labelCareers,
    privacyPolicy: labelPrivacyPolicy,
    privacy: labelPrivacy,
    termsAndConditions: labelTermsAndConditions,
  } = routes[pageContext.langKey]

  return (
    <footer className="root--footer">
      <div className="max-footer-1">
        <Container className="container footer--to-top">
          <div className="columns">
            <div className="column">
              <button
                onClick={onScrollTop}
                onKeyPress={onScrollTop}
                className="btn--to-top"
              >
                {t('global.btnBackToTop')}
              </button>
            </div>
          </div>
        </Container>
      </div>
      <div className="max-footer-2">
        <Container className="container footer--columns">
          <div className="columns is-multiline is-mobile">
            <div className="column column-1">
              <ul className="footer--links">
                <li>
                  <Link to={mappedRoutes.contact} className="">
                    {labelContact}
                  </Link>
                </li>
                <li>
                  <Link to={mappedRoutes.about} className="">
                    {labelAbout}
                  </Link>
                </li>
                <li>
                  <Link to={mappedRoutes.services} className="">
                    {labelServices}
                  </Link>
                </li>
                <li>
                  <Link to={mappedRoutes.faq} className="">
                    {labelFaq}
                  </Link>
                </li>
                <li>
                  <Link to={mappedRoutes.careers} className="">
                    {labelCareers}
                  </Link>
                </li>
                <li>
                  <Link to={mappedRoutes.privacyPolicy} className="">
                    {labelPrivacy}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="column column-2">
              <div className="column-content">
                <Link to={mappedRoutes.home}>
                  <Image svg={true} name="footerLogo" className="footer--logo" />
                </Link>
              </div>
            </div>
            <div className="column column-3">
              <div className="footer--social">
                <p>{t('global.labelFollowUs')}</p>
                <div className="footer--social-button">
                  <a
                    href={"https://www.instagram.com/bettytranconsultants"}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    className="social"
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: "24px" }}
                      icon={["fab", "instagram"]}
                    />
                  </a>
                  <a
                    href={"https://www.facebook.com/bettytranconsultants"}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    className="social"
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: "24px" }}
                      icon={["fab", "facebook-f"]}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column ">
              <div className="footer--betty-tran">
                <ul>
                  <li>
                    <p>@BETTY TRAN CONSULTANTS 2020</p>
                  </li>
                  <li>
                    <Link to={mappedRoutes.termsAndConditions} className="">
                      {labelTermsAndConditions}
                    </Link>
                  </li>
                  <li>
                    <Link to={mappedRoutes.privacyPolicy} className="">
                      {labelPrivacyPolicy}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
