import i18next from "i18next"

const LANGUAGE = {
  ENG: 'en',
  VIE: 'vi',
}
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      global: {
        btnSeemore: "See More",
        btnBackToTop: "Back To Top",
        labelFollowUs: "Follow us"
      },
    },
  },
  vi: {
    translation: {
      global: {
        btnSeemore: "Xem thêm",
        btnBackToTop: "Trở về đầu trang",
        labelFollowUs: "Follow us"
      },
    },
  },
}

i18next.init({
  fallbackLng: LANGUAGE.ENG,
  resources,
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
})

// i18next.languages = ["zh-Hant", "en"]
export {
  LANGUAGE
}

export default i18next
