// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import cx from 'classnames';
import { Sticky } from "react-sticky"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import Img from "components/Img"
import { useMappingRoutes } from 'utils/useMappingRoutes';
import { LANGUAGE } from "i18n/config";

const Header = ({ labelRoutes, pageContext, siteTitle, onOpenMenu, onChangeLanguage }) => {
  const mappedRoutes = useMappingRoutes(pageContext.langKey)
  const { routes } = labelRoutes;
  const {
    ourWorld: labelOurWorld,
    about: labelAbout,
    awards: labelAwards,
    bettyTranBrand: labelBettyTranBrand,
    community: labelCommunity,
    contact: labelContact,
    focus: labelFocus,
    people: labelPeople,
    services: labelServices,
    sustainability: labelSustainability,
    visionAndMission: labelVisionAndMission,
    expertise: labelExpertise,
    testimonials: labelTestimonials,
  } = routes[pageContext.langKey]

  return (
    <Sticky disableCompensation>
      {({ style }) => {
        return (
          <header
            className="root--header max-container max-header"
            style={style}
          >
            <div className="switch-language is-desktop-items">
              <div className="header--language-wrapper">
                <button
                  onClick={() => onChangeLanguage(LANGUAGE.ENG)}
                  onKeyDown={() => onChangeLanguage(LANGUAGE.ENG)}
                  className={cx("item-menu", {
                    'is-current-lang': Boolean(pageContext.langKey === LANGUAGE.ENG)
                  })}
                >
                  Eng
                </button>
                <button
                  onClick={() => onChangeLanguage(LANGUAGE.VIE)}
                  onKeyDown={() => onChangeLanguage(LANGUAGE.VIE)}
                  className={cx("item-menu", {
                    'is-current-lang': Boolean(pageContext.langKey === LANGUAGE.VIE)
                  })}
                >
                  Vie
                </button>
              </div>
            </div>
            <div className="container-narbar container max-1048">
              <nav className="level is-mobile navbar">
                <div className="level-left">
                  <div className="level-item">
                    <Link className="btc--primary-logo" to={mappedRoutes.home}>
                      <Img svg={true} name="primaryLogo" />
                    </Link>
                  </div>
                </div>
                <div className="level-right is-desktop-items">
                  <div className="level-item">
                    <div className="dropdown is-hoverable">
                      <div className="dropdown-trigger">
                        <button
                          className="item-menu"
                          aria-haspopup="true"
                          aria-controls="dropdown-menu"
                        >
                          {labelOurWorld}
                        </button>
                      </div>
                      <div
                        className="dropdown-menu"
                        id="dropdown-menu"
                        role="menu"
                      >
                        <div className="dropdown-content">
                          <Link
                            to={mappedRoutes.about}
                            className="item-menu dropdown-item"
                          >
                            {labelAbout}
                          </Link>
                          <Link
                            to={mappedRoutes.bettyTranBrand}
                            className="item-menu dropdown-item"
                          >
                            {labelBettyTranBrand}
                          </Link>
                          <Link
                            to={mappedRoutes.people}
                            className="item-menu dropdown-item"
                          >
                            {labelPeople}
                          </Link>
                          <Link
                            to={mappedRoutes.awards}
                            className="item-menu dropdown-item"
                          >
                            {labelAwards}
                          </Link>
                          <Link
                            to={mappedRoutes.visionAndMission}
                            className="item-menu dropdown-item"
                          >
                            {labelVisionAndMission}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="level-item">
                    <Link className="item-menu" to={mappedRoutes.focus}>
                      {labelFocus}
                    </Link>
                  </div>
                  <div className="level-item">
                    <div className="dropdown is-hoverable">
                      <div className="dropdown-trigger">
                        <button
                          className="item-menu"
                          aria-haspopup="true"
                          aria-controls="dropdown-menu"
                        >
                          {labelExpertise}
                        </button>
                      </div>
                      <div
                        className="dropdown-menu"
                        id="dropdown-menu"
                        role="menu"
                      >
                        <div className="dropdown-content">
                          <Link
                            to={mappedRoutes.services}
                            className="item-menu dropdown-item"
                          >
                            {labelServices}
                          </Link>
                          <Link
                            to={mappedRoutes.sustainability}
                            className="item-menu dropdown-item"
                          >
                            {labelSustainability}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="level-item">
                    <Link className="item-menu" to={mappedRoutes.contact}>
                      {labelContact}
                    </Link>
                  </div>
                  <div className="level-item">
                    <div className="dropdown is-hoverable">
                      <div className="dropdown-trigger">
                        <button
                          className="item-menu"
                          aria-haspopup="true"
                          aria-controls="dropdown-menu"
                        >
                          {labelCommunity}
                        </button>
                      </div>
                      <div
                        className="dropdown-menu"
                        id="dropdown-menu"
                        role="menu"
                      >
                        <div className="dropdown-content">
                          <Link className="item-menu dropdown-item" to={mappedRoutes.community}>
                            {labelCommunity}
                          </Link>
                          <Link className="item-menu dropdown-item" to={mappedRoutes.testimonials}>
                            {labelTestimonials}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="level-right is-mobile-item">
                  <div className="level-item">
                    <button
                      onClick={onOpenMenu}
                      className="mobile--menu-trigger"
                    >
                      <FontAwesomeIcon
                        style={{ fontSize: "24px" }}
                        icon={"bars"}
                      />
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </header>
        )
      }}
    </Sticky>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
