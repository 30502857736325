import { useStaticQuery, graphql } from "gatsby"

// Load svg https://github.com/gatsbyjs/gatsby/issues/10297
const useStaticImages = () => {
  const data = useStaticQuery(
    graphql`
      {
        primaryLogo: file(relativePath: { eq: "primaryLogo.png" }) {
          name
          childImageSharp {
            fixed(width: 92, quality: 95) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        footerLogo: file(relativePath: { eq: "logo--footer.png" }) {
          name
          childImageSharp {
            fixed(width: 120, quality: 95) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        homepageAbout: file(relativePath: { eq: "imgHomepage--about.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 720, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        homepagePeople: file(relativePath: { eq: "imgHomepage--people.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 720, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        homepageHeroImage: file(relativePath: { eq: "imgHomepage--hero-image.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        homepageQuoteImage: file(relativePath: { eq: "imgHomepage--quote-image-item.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1024, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bettyTranBrandHeroImage: file(relativePath: { eq: "btc-bg-image.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        focusHeroImage: file(relativePath: { eq: "pageFocus--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleHeroImage: file(relativePath: { eq: "pagePeople--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleBackgroundBlock2: file(relativePath: { eq: "pagePeople--image-2.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleImageShaunBio: file(relativePath: { eq: "pagePeople--image-3.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleImageShaunMission: file(relativePath: { eq: "pagePeople--image-4.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleImageBetty: file(relativePath: { eq: "pagePeople--image-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleImageGroupBetty: file(relativePath: { eq: "pagePeople--image-group.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleImage1: file(relativePath: { eq: "pagePeople--image-6.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleImage2: file(relativePath: { eq: "pagePeople--image-7.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleImage3: file(relativePath: { eq: "pagePeople--image-8.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        peopleImage4: file(relativePath: { eq: "pagePeople--image-9.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutBettyShaun: file(relativePath: { eq: "pageAbout--image-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1024, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutServiceImage1: file(relativePath: { eq: "pageAbout--service-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1024, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutServiceImage2: file(relativePath: { eq: "pageAbout--service-2.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1024, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutServiceImage3: file(relativePath: { eq: "pageAbout--service-3.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1024, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutServiceImage4: file(relativePath: { eq: "pageAbout--service-4.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1024, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutServiceImage5: file(relativePath: { eq: "pageAbout--service-5.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1024, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroAboutImage1: file(relativePath: { eq: "pageAbout--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroAboutImage2: file(relativePath: { eq: "pageAbout--hero-2.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroAboutImage3: file(relativePath: { eq: "pageAbout--hero-3.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroAboutImage4: file(relativePath: { eq: "pageAbout--hero-4.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroAwardImage1: file(relativePath: { eq: "pageAward--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroAwardImage2: file(relativePath: { eq: "pageAward--hero-2.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroAwardImage3: file(relativePath: { eq: "pageAward--hero-3.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroSustainabilityImage1: file(relativePath: { eq: "pageSustain--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroSustainabilityImage2: file(relativePath: { eq: "pageSustain--hero-2.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroSustainabilityImage3: file(relativePath: { eq: "pageSustain--hero-3.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroVisionMissionImage1: file(relativePath: { eq: "pageVision--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroVisionMissionImage2: file(relativePath: { eq: "pageVision--hero-2.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1480, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contactHeroImage: file(relativePath: { eq: "pageContact--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pageContactImage1: file(relativePath: { eq: "pageContact--image-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 720, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pageContactImage2: file(relativePath: { eq: "pageContact--image-2.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 720, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        communityHeroImage: file(relativePath: { eq: "pageCommunity--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        testimonialsHeroImage: file(relativePath: { eq: "pageTestimonials--hero.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        servicesHeroImage: file(relativePath: { eq: "pageServices--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        servicesImageCreativeDirection: file(relativePath: { eq: "pageService--image-2.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageDesign: file(relativePath: { eq: "pageService--image-3.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageBrandIdentity: file(relativePath: { eq: "pageService--image-4.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageCommunication: file(relativePath: { eq: "pageService--image-5.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageBrandStrategy: file(relativePath: { eq: "pageService--image-6.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageArchitecture: file(relativePath: { eq: "pageService--image-1.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImagePackaging: file(relativePath: { eq: "pageService--image-7.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageAdvertising: file(relativePath: { eq: "pageService--image-8.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageInnovationLabs: file(relativePath: { eq: "pageService--image-9.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageCrisisMngt: file(relativePath: { eq: "pageService--image-10.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageMediaTraining: file(relativePath: { eq: "pageService--image-11.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageSalesLeads: file(relativePath: { eq: "pageService--image-12.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        servicesImageCaptitalRaising: file(relativePath: { eq: "pageService--image-13.jpg" }) {
          name
          childImageSharp {
            ...ServiceBlockImages
          }
        }
        faqHeroImage: file(relativePath: { eq: "pageFAQ--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        careersHeroImage: file(relativePath: { eq: "pageCareers--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        privacyPolicyHeroImage: file(relativePath: { eq: "pagePrivacyPolicy--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        termsHeroImage: file(relativePath: { eq: "pageTerms--hero-1.jpg" }) {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  if (!data) return null

  return data
}

export { useStaticImages }
