import { useStaticQuery, graphql } from "gatsby"

const usePageData = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site: site {
        siteMetadata {
          title
        }
      }
      routesLabel: allDataYaml(filter: { codePage: { eq: "globalVar" } }) {
        nodes {
          ...fragmentGlobalVariables
        }
      }
    }
  `)

  if (!data) return null

  return {
    data: data.site,
    globalRoutes: data.routesLabel.nodes[0]
  }
}

export default usePageData
