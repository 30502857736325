import reduce from "lodash/reduce"
import keys from "lodash/keys"
import { LANGUAGE } from "i18n/config";

const routeConfig = {
  home: "/",
  about: "/about",
  awards: "/awards",
  bettyTranBrand: "/betty-tran-brand",
  community: "/community",
  contact: "/contact",
  focus: "/focus",
  people: "/people",
  services: "/services",
  sustainability: "/sustainability",
  visionAndMission: "/vision-and-mission",
  faq: "/faq",
  careers: "/careers",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
  testimonials: "/testimonials",
}

const mappingRouteLang = (routes, lang = "en") => {
  if (!lang || lang === "en") return routes

  return reduce(
    keys(routes),
    (acc, current) => ({
      ...acc,
      [current]: current === 'home' ? `/vi` : `/vi${routes[current]}`,
    }),
    {}
  )
}

const modifyRouteNavigation = (route, lang) => {
  try {
    // Switch from `en` to `vi`
    // /contact => /vi/contact
    if (lang === LANGUAGE.ENG) {
      if (route === '/')
        return `/${LANGUAGE.VIE}`;

      return `/${LANGUAGE.VIE}${route}`;
    }

    // Switch from `vi` to en`
    // /vi/contact => /contact
    if (lang === LANGUAGE.VIE) {
      if (route === `/${LANGUAGE.VIE}/`)
        return `/`;

      const index = route.indexOf('/vi/');
      const newRoute = route.substring(index + 3, route.length);

      return  `${newRoute}`;
    }
  } catch (error) {
    debugger;
    return "/"
  }
}

export {
  modifyRouteNavigation,
  routeConfig,
  mappingRouteLang,
}