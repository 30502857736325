import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import { useStaticImages } from "hooks/queryImages"
import { useSVGIcons } from "hooks/querySVGUtils"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
const Image = ({ svg, alt, className, name, isFluid, imageProps }) => {
  const data = useStaticImages()
  const dataSVG = useSVGIcons()

  if (svg) {
    return (
      <img
        className={`image-svg ${className}`}
        src={dataSVG[name].publicURL}
        alt={alt}
      />
    )
  }

  const img = data[name]

  if (!img) return null

  if (isFluid)
    return (
      <Img
        {...imageProps}
        className={`image-normal ${className}`}
        fluid={img.childImageSharp.fluid}
      />
    )

  return (
    <Img
      {...imageProps}
      className={`image-normal ${className}`}
      fixed={img.childImageSharp.fixed}
    />
  )
}

const Photo = ({isFixed, alt, data, ...others}) => {
  if (!data) return null;

  if (isFixed)
    return <Img {...others} fixed={data.childImageSharp.fixed} alt={alt} />

  return <Img {...others} fluid={data.childImageSharp.fluid} alt={alt} />
}

Photo.defaultProps = {
  isFixed: false,
  alt: '',
  data: undefined,
}

Image.defaultProps = {
  className: "",
  name: undefined,
  svg: false,
  isFluid: false,
  alt: "",
  imageProps: {}
}

export { Photo }
export default Image