/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StickyContainer } from "react-sticky"

import { I18nextProvider } from "react-i18next"
import i18nConfig from "i18n/config"

import Header from "./header"
import Footer from "components/Footer"
import MobileMenu from "components/MobileMenu"
import usePageData from 'hooks/queryLayout';
import { navigate } from "@reach/router"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { faBars, faTimes, faGlobe } from "@fortawesome/free-solid-svg-icons"
import { LanguageContext } from "i18n/context"
import { LANGUAGE } from "i18n/config";
import { modifyRouteNavigation } from 'utils/route.utils';
/**
 * Import CSS from 3rd parties and primary CSS
 */
import "swiper/css/swiper.min.css"
import "rodal/lib/rodal.css"
import "styles/main.scss"

library.add(faInstagram, faFacebookF, faBars, faTimes, faGlobe)
const Layout = ({ pageContext, children, className, ...others }) => {
  console.log(`Layout -> pageContext`, pageContext)
  const [currLanguage, setLanguage] = useState("en")
  const contextLanguage = {
    language: currLanguage,
    setLanguage,
  }
  const { data, globalRoutes } = usePageData();

  // eslint-disable-next-line
  const [currentMenuView, setCurrentMenuView] = useState(undefined)
  const [isOpen, setOpen] = useState(false)
  const switchLanguage = useCallback(lang => {
    try {
      if (!pageContext.slug)
        throw new Error('Error pageContext')

      const { langKey } = pageContext;
      // navigate(pageContext.slug);
      navigate(modifyRouteNavigation(pageContext.slug, langKey))
    } catch (err) {
      lang === LANGUAGE.VIE ? navigate(`/${LANGUAGE.VIE}`) : navigate("/")
    }
  }, [pageContext])
  const updateMenu = value => setCurrentMenuView(value)
  const onClickHandler = e => {
    e.preventDefault()
    setOpen(!isOpen)
  }

  useEffect(() => {
    if (!pageContext) {
      return
    }

    if (pageContext) {
      if (pageContext.langKey === LANGUAGE.VIE) {
        i18nConfig.changeLanguage(LANGUAGE.VIE)
        setLanguage(LANGUAGE.VIE)
      }

      if (pageContext.langKey === LANGUAGE.ENG) {
        i18nConfig.changeLanguage(LANGUAGE.ENG)
        setLanguage(LANGUAGE.ENG)
      }
    }
  }, [pageContext])

  return (
    <I18nextProvider i18n={i18nConfig}>
      <LanguageContext.Provider value={contextLanguage}>
        <StickyContainer className={`root lang--${pageContext.langKey} ${className}`}>
          <MobileMenu
            labelRoutes={globalRoutes}
            pageContext={pageContext}
            onClickHandler={onClickHandler}
            isOpen={isOpen}
            setOpen={setOpen}
            onClickMenu={updateMenu}
            onChangeLanguage={switchLanguage}
          />
          <Header
            labelRoutes={globalRoutes}
            pageContext={pageContext}
            onOpenMenu={onClickHandler}
            onChangeLanguage={switchLanguage}
            siteTitle={data.siteMetadata.title}
          />
          <main className="root--body">{children}</main>
          <Footer labelRoutes={globalRoutes} pageContext={pageContext} />
        </StickyContainer>
      </LanguageContext.Provider>
    </I18nextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  pageContext: undefined,
  className: '',
}

export default Layout
