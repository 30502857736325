import { useStaticQuery, graphql } from "gatsby"

// Load svg https://github.com/gatsbyjs/gatsby/issues/10297
const useSVGIcons = () => {
  const data = useStaticQuery(
    graphql`
      {
        homepage: file(relativePath: { eq: "imgHomepage--about.jpg" }) {
          id
          publicURL
        }
        primaryLogo: file(relativePath: { eq: "logoHeader.svg" }) {
          id
          publicURL
        }
        footerLogo: file(relativePath: { eq: "logoFooter.svg" }) {
          id
          publicURL
        }
        iconFAQOpen: file(relativePath: { eq: "iconFAQOpen.svg" }) {
          id
          publicURL
        }
        iconFAQClose: file(relativePath: { eq: "iconFAQClose.svg" }) {
          id
          publicURL
        }
      }
    `
  )

  return data
}

export { useSVGIcons }
