import React, { useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Link } from "gatsby";

function CustomLink({ ...props }) {
  const {
    className,
    classNameActive,
    classNameHasActiveChild,
    // active,
    hasActiveChild,
    to,
    externalLink,
    hasSubMenu,
    toggleSubMenu,
    activateMe,
    children,
    label,
  } = props
  // eslint-disable-next-line
  const [isActive, setActive] = useState(false)

  const onClickHandler = e => {
    if (hasSubMenu) toggleSubMenu(e)
    else {
      activateMe({
        newLocation: to,
        selectedMenuLabel: label,
      })
    }
  }

  if (externalLink) {
    return (
      <a
        href={to}
        className={classnames(
          className,
          isActive && classNameActive,
          hasActiveChild && classNameHasActiveChild
        )}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <Link
      className={classnames(
        className,
        isActive && classNameActive,
        hasActiveChild && classNameHasActiveChild
      )}
      onClick={onClickHandler}
      to={to}
    >
      {children}
    </Link>
  )
}

CustomLink.defaultProps = {
  externalLink: false,
  toggleSubMenu: null,
}

CustomLink.propTypes = {
  className: PropTypes.string.isRequired,
  classNameActive: PropTypes.string.isRequired,
  classNameHasActiveChild: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  hasActiveChild: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  externalLink: PropTypes.bool,
  hasSubMenu: PropTypes.bool.isRequired,
  toggleSubMenu: PropTypes.func,
  activateMe: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
}

export default CustomLink
