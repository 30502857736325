import React from "react"
import Menu from "react-burger-menu/lib/menus/slide"
import MetisMenu from "react-metismenu"
import { motion } from "framer-motion"
import CustomLink from "components/CustomLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-metismenu/dist/react-metismenu-standart.min.css";
import cx from 'classnames';
import { LANGUAGE } from "i18n/config";
import { useMappingRoutes } from 'utils/useMappingRoutes';

const generateMobileContent = ({schema, routes, langKey = 'en'}) => ([
  {
    icon: "",
    label: routes[langKey].ourWorld,
    content: [
      {
        icon: "",
        label: routes[langKey].about,
        to: schema.about,
      },
      {
        icon: "",
        label: routes[langKey].bettyTranBrand,
        to: schema.bettyTranBrand,
      },
      {
        icon: "",
        label: routes[langKey].people,
        to: schema.people,
      },
      {
        icon: "",
        label: routes[langKey].awards,
        to: schema.awards,
      },
      {
        icon: "",
        label: routes[langKey].visionAndMission,
        to: schema.visionAndMission,
      },
    ],
  },
  {
    icon: "",
    label: routes[langKey].focus,
    to: schema.focus,
  },
  {
    icon: "",
    label: routes[langKey].expertise,
    content: [
      {
        icon: "",
        label: routes[langKey].services,
        to: schema.services,
      },
      {
        icon: "",
        label: routes[langKey].sustainability,
        to: schema.sustainability,
      },
    ],
  },
  {
    icon: "",
    label: routes[langKey].contact,
    to: schema.contact,
  },
  {
    icon: "",
    label: routes[langKey].community,
    content: [
      {
        icon: "",
        label: routes[langKey].community,
        to: schema.community,
      },
      {
        icon: "",
        label: routes[langKey].testimonials,
        to: schema.testimonials,
      },
    ]
  },
])

const MobileMenu = ({
  labelRoutes,
  pageContext,
  onClickHandler,
  isOpen,
  setOpen,
  onChangeLanguage,
}) => {
  const { langKey } = pageContext;
  const { routes } = labelRoutes;
  const mappedRoutes = useMappingRoutes(langKey)
  const finalRoutes = generateMobileContent({
    schema: mappedRoutes,
    routes,
    langKey
  });

  return (
    <Menu
      right
      customBurgerIcon={false}
      customCrossIcon={false}
      onStateChange={state => setOpen(state.isOpen)}
      isOpen={isOpen}
      className="btc--mobile-menu"
    >
      <div className="wrapper--mobile-menu">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onClickHandler}
          className="btn--close-mobile-menu menu-item--close"
        >
          {/* <Img name="iconMobileClose" svg /> */}
          <FontAwesomeIcon icon={"times"} />
        </motion.button>
        <MetisMenu
          className="vertical-nav-menu"
          content={finalRoutes}
          LinkComponent={CustomLink}
        />
        <div className="mobile-switch-language">
          <div className="header--language-wrapper">
            <button
              onClick={() => onChangeLanguage(LANGUAGE.ENG)}
              onKeyDown={() => onChangeLanguage(LANGUAGE.ENG)}
              className={cx("item-menu", {
                'is-current-lang': Boolean(pageContext.langKey === LANGUAGE.ENG)
              })}
            >
              Eng
            </button>
            <button
              onClick={() => onChangeLanguage(LANGUAGE.VIE)}
              onKeyDown={() => onChangeLanguage(LANGUAGE.VIE)}
              className={cx("item-menu", {
                'is-current-lang': Boolean(pageContext.langKey === LANGUAGE.VIE)
              })}
            >
              Vie
            </button>
          </div>
        </div>
      </div>
    </Menu>
  )
}

export default MobileMenu
